import { useEffect, useState } from 'react';

import { Colors } from 'tailwind-config';

const icons = {
  ArrowRandom: 'arrow-random',
  Additional: 'additional',
  DownDirection: 'down-direction',
  Logo: 'logo',
  Search: 'search',
  LeftDirection: 'left-direction',
  Phone: 'phone',
  Best: 'best',
  Again: 'again',
  NoData: 'no-data',
  Location: 'location',
  Bus: 'bus',
  Bookable: 'bookable',
  YellowStar: 'yellow-star',
  CircleCheck: 'circle-check',
  Motor: 'motor',
  Driving: 'driving',
  Scooter: 'scooter',
  Question: 'question',
  Close: 'close',
  My: 'my',
  Reward: 'reward',
  Home: 'home',
  Info: 'info',
  Tip: 'tip',
  ActiveTip: 'active-tip',
  ActiveHome: 'active-home',
  ActiveMy: 'active-my',
  NewSad: 'new-sad',
  NewSmile: 'new-smile',
  GreenInfo: 'green-info',
  Notification: 'notification',
  RightDirection: 'right-direction',
  BusTip: 'bus-tip',
  CarTip: 'car-tip',
  MotorTip: 'motor-tip',
  ScooterTip: 'scooter-tip',
  TruckTip: 'truck-tip',
  TrailerTip: 'trailer-tip',
  TractionTip: 'traction-tip',
  CarTruckTip: 'car-truck-tip',
  View: 'view',
  NotCheck: 'not-check',
  Check: 'check',
  GrayCloseSmall: 'gray-close-small',
  AllCheck: 'all-check',
  LogoGrey: 'logo-grey',
  Profile: 'profile',
  Sad: 'sad',
  LogoOutlined: 'logo-outlined',
  Happy: 'happy',
  Calendar: 'calendar',
  Warning: 'warning',
  Plus: 'plus',
  Zoom: 'zoom',
  UpDirection: 'up-direction',
  Delete: 'delete',
  SmallDownDirection: 'small-down-direction',
  SmallRightDirection: 'small-right-direction',
  Star: 'star',
  Clock: 'clock',
  Trash: 'trash',
  InfoGreen: 'info-green',
  Ellipsis: 'ellipsis',
  CalendarCancel: 'calendar-cancel',
  CheckGreen: 'check-green',
  Refresh: 'refresh',
  CheckCalendar: 'check-calendar',
  BlogDownDirection: 'blog-down-direction',
  BlogUpDirection: 'blog-up-direction',
  EventChip: 'event-chip',
  GrayClose: 'gray-close',
  GoldOneLarge: 'gold-one-large',
  GoldOneNormal: 'gold-one-normal',
  GoldFree: 'gold-free',
  GoldMotor: 'gold-motor',
  GoldScooter: 'gold-scooter',
  WhiteNotification: 'white-notification',
  ShuttleBus: 'shuttle-bus',
  BookableSmall: 'bookable-small',
  ShuttleBusSmall: 'shuttle-bus-small',
  DiscountChip: 'discount-chip',
  ReservationChip: 'reservation-chip',
  ShuttleChip: 'shuttle-chip',
  DiscountLargeChip: 'discount-large-chip',
  ReservationLargeChip: 'reservation-large-chip',
  ShuttleLargeChip: 'shuttle-large-chip',
  YellowCheck: 'yellow-check',
  GrayCheck: 'gray-check',
  KakaoBlack: 'kakao-black',
  Receipt: 'receipt',
  Gift: 'gift',
  Coupon: 'coupon',
  Money: 'money',
  Storage: 'storage',
  BlackProfile: 'black-profile',
  ProfileBlack: 'profile-black',
  Wrong: 'wrong',
  Correct: 'correct',
  Thunder: 'thunder',
  TestWrong: 'test-wrong',
  TestBookmark: 'test-bookmark',
  TestCategory: 'test-category',
  TestGraph: 'test-graph',
  TestTimer: 'test-timer',
  UnfilledStar: 'unfilled-star',
  FilledStar: 'filled-star',
  ThinDownDirection: 'thin-down-direction',
  TestContinue: 'test-continue',
  TestSubmit: 'test-submit',
  TestClose: 'test-close',
  TestCheck: 'test-check',
  DownDirectionSmall: 'down-direction-small',
  Test: 'test',
  ActiveTest: 'active-test',
  ExamClose: 'exam-close',
  ExamCheck: 'exam-check',
  GoldSmallTrailer: 'gold-small-trailer',
  GoldBigTrailer: 'gold-big-trailer',
  GoldTraction: 'gold-traction',
  Compass: 'compass',
  ActiveCompass: 'active-compass',
  GrayMarker: 'gray-marker',
  BlackMarker: 'black-marker',
  LowestChip: 'lowest-chip',
  LowestLargeChip: 'lowest-large-chip',
  GraphicBlue: 'graphic_blue',
  GraphicGreen: 'graphic_green',
  GraphicOrange: 'graphic_orange',
  Graphic: 'graphic',
  School: 'school',
  DiscountCoupon: 'discount_coupon',
  Celebrate: 'celebrate',
  NotionLogo: 'notion_logo',
  MyInfoBookmark: 'myinfo-bookmark',
  MyCoupon: 'my-coupon',
  SurpriseFace: 'surprise_face',
  CircleYellowCheck: 'circle_yellow_check',
  Bookmark: 'bookmark_new',
  RedInfo: 'red_info',
  Setting: 'setting',
  Community: 'community',
  CouponDivideLine: 'coupon-divide-line',
  SimulationCoupon: 'simulation-coupon',
  Download: 'download',
  GrayDownload: 'gray-download',
  SimulationSelectLicense: 'simulation-select-license',
  SimulationSelectTraining: 'simulation-select-training',
} as const;

export type IconType = keyof typeof icons;

export interface IconProps {
  icon: IconType;
  color?: keyof typeof Colors;
  size?: number;
  width?: number;
  height?: number;
}

export const Icon = ({ icon, size = 24, color = 'white', width, height }: IconProps) => {
  const [SvgIcon, setSvgIcon] = useState<React.ComponentType<React.SVGProps<SVGSVGElement>> | null>(
    null,
  );

  useEffect(() => {
    import(`../../assets/icon/${icons[icon]}.svg`)
      .then((iconModule) => {
        setSvgIcon(() => iconModule.default);
      })
      .catch((err) => console.error(`Icon not found: ${icons[icon]}.svg`, err));
  }, [icon]);

  return SvgIcon ? (
    <>
      <SvgIcon width={width ?? size} height={height ?? size} fill={Colors[color]} />
      <style jsx>{`
        svg {
          width: ${width ?? size}px;
          height: ${height ?? size}px;
          viewbox: 0 0 ${width ?? size} ${height ?? size};
        }

        path {
          fill: ${Colors[color]};
          stroke: ${Colors[color]};
        }
      `}</style>
    </>
  ) : (
    // placeholder
    <div
      style={{
        width: width ?? size,
        height: height ?? size,
      }}
    />
  );
};
