import { NotFound } from '@templates';

const NotFoundPage = () => {
  return (
    <div className='flex h-screen items-center'>
      <NotFound />
    </div>
  );
};

export default NotFoundPage;
