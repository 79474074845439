import { useRouter } from 'next/router';

import { Icon } from '@components';

export const NotFound = () => {
  const router = useRouter();

  const onClickToBack = () => {
    router.back();
  };

  const onClickToHome = () => {
    router.replace('/');
  };

  return (
    <>
      <div className='flex w-full flex-col items-center'>
        <Icon icon='NoData' size={36} />
        <div className='text-Grey text-T4 mt-12'>찾을 수 없는 페이지입니다.</div>
      </div>
      <div className='absolute bottom-30 flex w-full max-w-[500px] px-20'>
        <div
          className='mr-10 flex h-58 w-full cursor-pointer items-center justify-center rounded-md border-1 border-black bg-white'
          role='button'
          onClick={onClickToBack}>
          <div className='text-T4'>이전으로</div>
        </div>
        <div
          className='flex h-58 w-full cursor-pointer items-center justify-center rounded-md border-1 border-yellow bg-white'
          role='button'
          onClick={onClickToHome}>
          <div className='text-T4 text-yellow'>홈으로</div>
        </div>
      </div>
    </>
  );
};
